<template>
	 <div class="table">
		<div class="crumbs">
		    <el-breadcrumb separator="/">
		        <el-breadcrumb-item><i class="el-icon-lx-cascades"></i> 盲盒</el-breadcrumb-item>
		    </el-breadcrumb>
		</div>
		 <div class="container">
			 <div class="handle-box">
<!-- 			     <el-input v-model="name" placeholder="筛选关键词" class="handle-input mr10"></el-input>
				 <el-button type="primary" icon="search" @click="getData(1)" style="margin-left:10px;">搜索</el-button> -->
				 <el-button type="primary" @click="handleConfig">设置</el-button>
				 <el-button type="primary"  @click="handlePros">中奖记录</el-button>
<!-- 				 <el-button type="primary" @click="handleProsCate">游戏用户</el-button> -->
				 <el-button type="primary" style="float: right" @click="handleEdit(undefined, undefined, 1)">添加</el-button>
			 </div>
			 <el-table :data="data" border class="table" ref="multipleTable" @selection-change="handleSelectionChange">
				<el-table-column prop="id" label="编号" width="80" align="center" >
				 </el-table-column>
				 <el-table-column prop="gift_name" label="礼品名" width="100" align="center" >
				  </el-table-column>
					<el-table-column prop="b_image" align="center" label="礼品图">
						<template slot-scope="scope">
							<el-popover
									placement="left"
									title=""
									width="500"
									trigger="hover">
								<img :src="scope.row.b_image" style="max-width: 100%" />
								<img slot="reference" :src="scope.row.b_image" :alt="scope.row.b_image" style="max-width: 130px; height: auto; max-height: 100px">
							</el-popover>
						</template>
					</el-table-column>

				  <el-table-column prop="value" label="所需积分" width="120" align="center" >
				  </el-table-column>
				  <el-table-column prop="is_show" label="展示状态" width="180" align="center" >
				  </el-table-column>
				  <el-table-column prop="is_draw" label="是否抽" width="180" align="center" >
				  </el-table-column>
				  <el-table-column prop="start_value" label="概率开始值" width="180" align="center" >
				  </el-table-column>

				  <el-table-column label="操作" align="center" width="180">
					  <template slot-scope="scope">
						  <el-button type="text" icon="el-icon-edit" @click="handleEdit(scope.$index, scope.row, 2)">编辑</el-button>
						  <el-button type="text" icon="el-icon-delete" class="red" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
					  </template>
				  </el-table-column>
			 </el-table>
			 <div class="pagination">
			     <el-pagination background @current-change="handleCurrentChange" layout="prev, pager, next" :total="sumPage">
			     </el-pagination>
			 </div>
		 </div>
        <!-- 弹出框 -->
		 <el-dialog v-loading="loading" :visible.sync="editVisible" width="50%">
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="编辑" name="first">
                    <el-form ref="form" :rules="rules" :model="form" label-width="100px">
                        <el-form-item label="礼品名" prop="gift_name">
                            <el-input v-model="form.gift_name" style="width:400px" placeholder="请输入礼品名"></el-input>
                        </el-form-item>
                        <el-form-item label="数量" prop="value">
                            <el-input v-model="form.value" style="width:400px" placeholder="请输入数量"></el-input>
                        </el-form-item>                       
						<el-form-item label="缩略图">
							<el-upload
									class="avatar-uploader"
									name="image"
									with-credentials
									list-type="picture-card"
									:data="{id:this.form.imgid}"
									:action="uploadUrl()"
									:on-error="uploadError"
									:on-success="handleAvatarSuccess"
									:before-upload="beforeAvatarUpload"
									:on-progress="uploading"
									:show-file-list="false"
									:auto-upload="true"
									enctype="multipart/form-data">
								<img v-if="form.b_image" :src="form.b_image" class="avatar">
								<i v-else class="el-icon-plus"></i>
							</el-upload>
							<span style="color:red">建议尺寸200*200</span>
						</el-form-item>
<!--                        <el-form-item label="需要的分数" prop="need_score">
                            <el-input v-model="form.need_score" style="width:150px" placeholder="请输入分数"></el-input>
                        </el-form-item> -->
						<el-form-item label="概率开始值" prop="start_value">
						    <el-input v-model="form.start_value" style="width:150px" placeholder="请输入概率开始值"></el-input>
						</el-form-item>
						<el-form-item label="概率结束值" prop="end_value">
						    <el-input v-model="form.end_value" style="width:150px" placeholder="请输入概率结束值"></el-input>
						</el-form-item>

                        <el-form-item label="是否显示">
                            <el-switch v-model="form.is_show"></el-switch>
                        </el-form-item>
                        <el-form-item label="是否能抽中">
                            <el-switch v-model="form.is_draw"></el-switch>
                        </el-form-item>
                        <el-form-item label="是否是超值">
                            <el-switch v-model="form.is_cheap"></el-switch>
                        </el-form-item>
                        <el-form-item label="排序">
                            <el-input v-model="form.sort" style="width:150px"></el-input>
                            <span style="color:red">&nbsp;&nbsp;注：数值越大展示越靠前，不输入则默认排序</span>
                        </el-form-item>

                        <!--<el-form-item label="日期">-->
                        <!--<el-date-picker type="date" placeholder="选择日期" v-model="form.b_datetime" value-format="yyyy-MM-dd" style="width: 100%;"></el-date-picker>-->
                        <!--</el-form-item>-->
                    </el-form>
                </el-tab-pane>

            </el-tabs>


            <span slot="footer" class="dialog-footer">
                <el-button @click="editVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveEdit('form')">确 定</el-button>
            </span>

		  </el-dialog>		 
		  
		<el-dialog title="设置配置"  v-loading='loading' :visible.sync="dialogEmail" width="70%">
			<el-form :model="emailData" :rules="rules" label-width="150px">
				<el-form-item label="每次消耗量" prop="per_day">
				    <el-input v-model="emailData.per_day" style="width:350px"></el-input>
				</el-form-item>
				<el-form-item label="每日领取" prop="num">
				    <el-input v-model="emailData.num" style="width:350px"></el-input>
				</el-form-item>				
                <el-form-item label="活动开始" prop="active_start_time">
                   <el-date-picker type="date" placeholder="选择日期" v-model="emailData.active_start_time" value-format="yyyy-MM-dd" style="width: 100%;"></el-date-picker>
                </el-form-item>
                <el-form-item label="活动结束" prop="desc">
                   <el-date-picker type="date" placeholder="选择日期" v-model="emailData.desc" value-format="yyyy-MM-dd" style="width: 100%;"></el-date-picker>
                </el-form-item>
				<el-form-item label="分享图片">
					<el-upload
							class="avatar-uploader"
							name="image"
							with-credentials
							list-type="picture-card"
							:data="{id:this.emailData.imgid}"
							:action="uploadUrl()"
							:on-error="uploadError"
							:on-success="handleAvatarSuccess2"
							:before-upload="beforeAvatarUpload"
							:on-progress="uploading"
							:show-file-list="false"
							:auto-upload="true"
							enctype="multipart/form-data">
						<img v-if="emailData.b_image" :src="emailData.b_image" class="avatar">
						<i v-else class="el-icon-plus"></i>
					</el-upload>
					<span style="color:red">建议尺寸200*200</span>
				</el-form-item>
                <el-form-item label="活动规则">
                    <vue-ueditor-wrap v-model="emailData.value" :config="myConfig"></vue-ueditor-wrap>
                </el-form-item>
						
						
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogEmail = false">关 闭</el-button>
				<el-button type="primary" @click="saveConfig('emailData')">确 定</el-button>
			</span>
		</el-dialog>
		  
		  <el-dialog title="中奖列表" v-loading="loading" :visible.sync="dialogProsTable" width="70%">
<!-- 			   <el-button type="primary"  @click="handleProsEdit(undefined, undefined, 1)">添加</el-button> -->
			   <el-table :data="prosTableData" border class="table">
				   <el-table-column prop="id" label="编号" width="100" align="center" sortable></el-table-column>
				   <el-table-column prop="nickname" label="用户名" width="180" align="center" ></el-table-column>
				    <el-table-column prop="gift_name" label="奖品名" width="100" align="center" ></el-table-column>
					<el-table-column prop="avatarurl"  align="center" label="头像">
						<template   slot-scope="scope">
							<el-popover
									placement="left"
									title=""
									width="500"
									trigger="hover">
								<img :src="scope.row.avatarurl" style="max-width: 100%" />
								<img slot="reference" :src="scope.row.avatarurl" :alt="scope.row.avatarurl" style="max-width: 130px; height: auto; max-height: 100px">
							</el-popover>
							<!--<img :src="scope.row.b_image"  min-width="70" height="70" />-->
						</template>
					</el-table-column>
					<el-table-column prop="get_prize_time" label="中奖时间" width="180" align="center" ></el-table-column>
					<el-table-column  label="状态" width="180" align="center" >
						<template slot-scope="scope">
							<span v-if="scope.row.is_use == 1">已使用</span>
							<span v-else>未使用</span>
						</template>
					</el-table-column>
<!-- 				   <el-table-column label="操作" align="center">
						  <template slot-scope="scope">
							  <el-button type="text" icon="el-icon-edit" @click="handleProsEdit(scope.$index, scope.row, 2)">编辑</el-button>
							  <el-button type="text" icon="el-icon-delete" class="red" @click="handleDeletePros(scope.$index, scope.row)">删除</el-button>
						  </template>
				   </el-table-column> -->
			   </el-table>
			   <div class="pagination">
			       <el-pagination background @current-change="handleProsCurrentChange" layout="prev, pager, next" :total="prosSumPage">
			       </el-pagination>
			   </div>
		  </el-dialog>
		  <el-dialog title="游戏用户" v-loading="loading" :visible.sync="dialogProsCateTable" width="80%">
<!-- 			  <el-button type="primary"  @click="handleProsCateEdit(undefined, undefined, 1)">添加</el-button> -->
			  <el-table :data="prosCateTableData" border class="table">
				   <el-table-column prop="id" label="编号" width="180" align="center" sortable></el-table-column>
				   <el-table-column prop="uid" label="UID" width="180" align="center" ></el-table-column>
				    <el-table-column prop="nickname" label="昵称" width="180" align="center" ></el-table-column>
					<el-table-column prop="avatarurl" label="头像" width="180" align="center" ></el-table-column>
					<el-table-column prop="tel" label="手机号" width="180" align="center" ></el-table-column>
<!-- 				   <el-table-column label="操作" align="center">
						  <template slot-scope="scope">
							  <el-button type="text" icon="el-icon-edit" @click="handleProsCateEdit(scope.$index, scope.row, 2)">编辑</el-button>
							  <el-button type="text" icon="el-icon-delete" class="red" @click="handleDeleteProsCate(scope.$index, scope.row)">删除</el-button>
						  </template>
				   </el-table-column> -->
			  </el-table>
		  </el-dialog>
		
			<el-dialog title="添加科室" v-loading="loading" ref="prosCateForm" :visible.sync="dialogAddProsCate" width="30%">
				<el-form ref="prosForm" :model="prosCateForm" label-width="130px">
				    <el-form-item label="科室名称" prop="cate_name">
				        <el-input v-model="prosCateForm.cate_name" style="width:400px" placeholder="请输入名称科室"></el-input>
				    </el-form-item>
					<el-form-item label="选择项目最大值" prop="choose_pro_max">
					    <el-input v-model="prosCateForm.choose_pro_max" style="width:400px" placeholder=""></el-input>
						<span>一次提交限制预约该科室项目最大值</span>
					</el-form-item>
					<el-form-item label="间隔时间" prop="time_peri">
					    <el-input v-model="prosCateForm.time_peri" style="width:400px" placeholder=""></el-input>
						<span>用于列出时间表</span>
					</el-form-item>
					<el-form-item label="状态" prop="is_show">
						 <el-radio v-model="prosCateForm.is_show"  label="0" >禁用</el-radio>
						  <el-radio v-model="prosCateForm.is_show" label="1">启用</el-radio>
					</el-form-item>
					<el-form-item label="排序" prop="sort">
					    <el-input v-model="prosCateForm.sort" style="width:400px" placeholder="请输入排序"></el-input>
						<span>排序值越小，排位越靠前</span>
					</el-form-item>
				</el-form>
				<span slot="footer" class="dialog-footer">
				    <el-button @click="dialogAddProsCate = false">关 闭</el-button>
				    <el-button type="primary" @click="saveProsCate()">确 定</el-button>
				</span>
			</el-dialog>
			

			
			<el-dialog title="添加项目" v-loading="loading" ref="prosForm" :visible.sync="dialogAddPros" width="30%">
				<el-form ref="prosForm" :model="prosForm" label-width="130px">
					<el-form-item label="项目名称" prop="pro_name">
					    <el-input v-model="prosForm.pro_name" style="width:400px" placeholder="请输入名称"></el-input>
					</el-form-item>
					<el-form-item label="科室" prop="">
					    <el-select v-model='prosForm.cate' placeholder="请选择科室">
							<el-option
							        v-for="item in prosCateTableData"
							        :key="item.id"
							        :label="item.cate_name"
							        :value="item.id">{{item.title}}
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="状态" prop="is_show">
						 <el-radio v-model="prosForm.is_show"  label="0" >禁用</el-radio>
						  <el-radio v-model="prosForm.is_show" label="1">启用</el-radio>
					</el-form-item>
					<el-form-item label="排序" prop="sort">
					    <el-input v-model="prosForm.sort" style="width:400px" placeholder="请输入排序"></el-input>
						<span>排序值越小，排位越靠前</span>
					</el-form-item>
				</el-form>
				<span slot="footer" class="dialog-footer">
				    <el-button @click="dialogAddPros = false">关 闭</el-button>
				    <el-button type="primary" @click="savePros()">确 定</el-button>
				</span>
			</el-dialog>
			
			<!-- 删除提示框 -->
			<el-dialog title="提示" :visible.sync="delVisible" width="300px" center>
			    <div class="del-dialog-cnt">删除不可恢复，是否确定删除？</div>
			    <span slot="footer" class="dialog-footer">
			        <el-button @click="delVisible = false">取 消</el-button>
			        <el-button type="primary" @click="deleteRow(delMode)">确 定</el-button>
			    </span>
			</el-dialog>
	</div>
</template>

<script>
	import 'quill/dist/quill.core.css';
	import 'quill/dist/quill.snow.css';
	import 'quill/dist/quill.bubble.css';
	import {quillEditor, Quill} from 'vue-quill-editor'
	import {container, ImageExtend, QuillWatch} from 'quill-image-extend-module';
	Quill.register('modules/ImageExtend', ImageExtend)
	
	export default {
		name: 'basetable',
		components: {
		    quillEditor
		},
		 data() {
			 return{
				 myConfig: {
				     // 编辑器不自动被内容撑高
				     autoHeightEnabled: false,
				     // 初始容器高度
				     initialFrameHeight: 600,
				     // 初始容器宽度
				     initialFrameWidth: '90%',
				     zIndex: 9999,
				     // 上传文件接口（这个地址是我为了方便各位体验文件上传功能搭建的临时接口，请勿在生产环境使用！！！）
				     serverUrl: this.$api.editorUrl,
				     // UEditor 资源文件的存放路径，如果你使用的是 vue-cli 生成的项目，通常不需要设置该选项，vue-ueditor-wrap 会自动处理常见的情况，如果需要特殊配置，参考下方的常见问题2
				     UEDITOR_HOME_URL: '/ueditor/'
				 },
				 tableData: [],
				 AddOrSave:'',  //1表示添加，2表示更新
				 cur_page: 1,  //当前页
				 number: 10,  //每页显示条数
				 sumPage:10,  //总页数
				 pros_cur_page:1,
				 prosSumPage:10, 
				 prosTableData:[],
				 prosCateTableData:[],
				 multipleSelection: [],
				 dialogProsTable:false,
				 dialogProsCateTable:false,
				 dialogAddProsCate:false,
				 dialogAddPros:false,
				 editVisible: false,
				 name:'',
				 mobile:'',
				 loading:false, //加载中
				 del_list: [],
				 activeName: 'first',  //默认选中tab
				 prosForm:{
					 id:'',
					 pro_name:'',
					 cate:'',
					 is_show:1,
					 sort:0
				 },
				 form: {
				     id: '',
				     ordernumber: '',
				     nickname: '',
				     name: '',
					 imgid: null,
					 b_image: null,
				 },
				 detailList: [],
				 prosCateForm:{
					 id:'',
					 cate_name:'',
					 is_show:1,
					 sort:0,
					 choose_pro_max:0,
					 time_peri:0
				 },
				 delMode:'Box',
				 delVisible:false,
				 dialogEmail:false,
				 appointid:'',
				 rules: {
					gift_name: [
                        { required: true, message: '请输入礼品名', trigger: 'blur' }
                    ],
                    value: [
                        { required: true, message: '请输入数量', trigger: 'blur' }
                    ],                   
                    // need_score: [
                    //     { required: true, message: '请输入分数', trigger: 'blur' }
                    // ],
                    start_value:[
                        { required: true, message: '请输入概率开始值', trigger: 'blur' }
                    ],
                    end_value:[
                        { required: true, message: '请输入概率结束值', trigger: 'blur' }
                    ],
					per_day: [
					    { required: true, message: '每次消耗量', trigger: 'blur' }
					],
					num: [
					    { required: true, message: '每日领取', trigger: 'blur' }
					],                   
					active_start_time: [
					    { required: true, message: '活动开始', trigger: 'blur' }
					],
					desc:[
					    { required: true, message: '活动结束', trigger: 'blur' }
					],
                 },		 
				 emailData:{
					id:'',
					per_day:'',
					num:'',
					active_start_time:'',
					desc:'',
					share_img:'',
					value:'',
					imgid: null,
					b_image: null,
				 }
			 }
		 },
		 created() {
		     this.getData();
		
		 },
		 computed: {
		     data() {
		         return this.tableData.filter((d) => {
		             let is_del = false;
		             for (let i = 0; i < this.del_list.length; i++) {
		                 if (d.name === this.del_list[i].name) {
		                     is_del = true;
		                     break;
		                 }
		             }
		             return d;
		         })
		     }
		 },
		  methods: {
			  //设置上传图片接口地址
			  uploadUrl(){
			      var url=this.$api.uploadUrl + "/Images/upload";
			      return url
			  },
			  handleClick(tab, event) {
			      // console.log(tab, event);
			  },
			  //图片上传之前
			  beforeAvatarUpload(file){
			      // console.log(file);
			  },
			  //正在上传中
			  uploading(event, file, fileList){
			      // console.log(event);
			      // console.log(file);
			      // console.log(fileList);
			  },
			  //图片上传失败
			  uploadError(err){
			      this.$message.error(err.msg);
			  },
			  //图片上传成功
			  handleAvatarSuccess(res, file){
			      
			      this.form.imgid=res.data;
			      this.form.b_image = URL.createObjectURL(file.raw);


				  // this.handleConfig();
			      this.$message.success(res.msg);
			  },
			  handleAvatarSuccess2(res, file){
			      
			      this.emailData.imgid=res.data;
			      this.emailData.b_image = URL.createObjectURL(file.raw);

				  this.handleConfig();
			      this.$message.success(res.msg);
			  },

			  getData(){
				  var params=this.$qs.stringify({
				      name: this.name,
				      mobile: this.mobile,
					  pageIndex: this.cur_page,
					  number: this.number,
					  activity_type:3,
				  });
				  console.log(params);
				  this.$api.post('Box/ajax_prize_list', params, res => {
				      this.tableData = res.data.list;
				      this.sumPage = res.data.sumPage*10;
				      this.cur_page = res.data.currentPage;
				      console.log(res);
				  }, err => {
				      this.tableData = [];
				      this.sumPage = 1*10;
				      this.cur_page = 1;
				      this.$message.error(err.msg);
				  });
			  },
			handleEdit(index, row, status) {
				// var params=this.$qs.stringify({
				//     id: row.id,
				// });
				this.AddOrSave=status;
			    //如果是添加则把form清空
			    if(status==1){
			        this.form = {
			            id: null,
			            gift_name: null,
			            img: null,
			            value: null,
			            is_show: null,
			            start_value: null,
			            is_draw: null,
						//need_score:null,
						sort:null,
			            limit: 999,
						imgid: null,
						b_image: null,
			        };
			    }
			    if(index!=undefined && row!=undefined){

			        this.idx = index;
			        const item = this.tableData[index];
				
			        this.form = {
			            id: item.id,
			            gift_name: item.gift_name,
			            img: item.img,
			            value: item.value,
			            start_value: item.start_value,
						end_value: item.end_value,
						//need_score:item.need_score,
						sort:item.sort,
			            is_draw: item.is_draw==1?true:false,
						is_show: item.is_show==1?true:false,
						is_cheap: item.is_cheap==1?true:false,
						imgid: item.imgid,
						b_image: item.b_image,
			        };
			    }
				console.log(this.form);
			    this.activeName = "first";  //默认选中第一个Tab
			    this.editVisible = true;

			},
            handleDelete(index, row) {
                this.idx = index;
                this.form = row;
                this.delVisible = true;
				this.delMode = 'Box'
				
            },            // 保存编辑
            saveEdit(formName) {
                // this.$set(this.tableData, this.idx, this.form);
                this.$refs[formName].validate((valid) => {
                    if (valid) {
						
						if(!this.form.imgid){
							this.$message.error('请上传图片');
							return false;
						}
                        this.editVisible = false;
                        var params=null;
                        //1表示添加，2表示更新
                        if(this.AddOrSave==1){
                            params=this.$qs.stringify({
                                gift_name: this.form.gift_name,
                                img: this.form.img,
                                value: this.form.value,
								start_value:this.form.start_value,
                                end_value: this.form.end_value,
                                is_draw: this.form.is_draw ? 1 : 0,
                                is_show: this.form.is_show ? 1 : 0,
                                is_cheap: this.form.is_cheap ? 1 : 0,
								//need_score:this.form.need_score,
								sort:this.form.sort,
								activity_type:3,
								imgid: this.form.imgid,
                            });
                        }else{
                            params=this.$qs.stringify({
                                id: this.form.id,
                                gift_name: this.form.gift_name,
                                img: this.form.img,
                                value: this.form.value,
								start_value:this.form.start_value,
                                end_value: this.form.end_value,
								//need_score:this.form.need_score,
								sort: this.form.sort,
                                is_draw: this.form.is_draw ? 1 : 0,
                                is_show: this.form.is_show ? 1 : 0,
                                is_cheap: this.form.is_cheap ? 1 : 0,
								imgid: this.form.imgid,
                            });
                        }
        //                 console.log({

        //                         id: this.form.id,
        //                         gift_name: this.form.gift_name,
        //                         img: this.form.img,
        //                         value: this.form.value,
								// start_value:this.form.start_value,
        //                         end_value: this.form.end_value,
        //                         is_draw: this.form.is_draw ? 1 : 0,
        //                         is_show: this.form.is_show ? 1 : 0,
        //                         is_cheap: this.form.is_cheap ? 1 : 0,
        //                 });
// return;
                        this.$api.post('Box/save_prize', params, res => {
                            this.getData();
                            this.$message.success(res.msg);
                            console.log(res);

                        }, err => {
                            this.$message.error(err.msg);
                        });
                    }else{
                        console.log("请填写所需数据");
                        return false;
                    }
                });
                // this.$message.success(`修改第 ${this.idx+1} 行成功`);
            },
			  handlePros(){	
				var params=this.$qs.stringify({
					  activity_type: 3,
					  number: this.number,
					  pageIndex: this.pros_cur_page,
				});
			  	this.$api.post('Box/prize_record_ajax', params, res => {
			  	    this.prosTableData = res.data.list;
					this.dialogProsTable = true
					this.prosSumPage = res.data.sumPage*10;
					this.pros_cur_page = res.data.currentPage;
			  	}, err => {
				      this.prosTableData = [];
				      this.prosSumPage = 1*10;
				      thispros_cur_page = 1;
				      this.$message.error(err.msg);
				  });
			  },
			  handleProsCate(){	//游戏用户
				var params=this.$qs.stringify({
					  activity_type: 3,
					  number: this.number,
					  pageIndex: this.pros_cur_page,
				});
			  	this.$api.post('Box/ajax_player_users', params, res => {
			  	   this.prosCateTableData = res.data.list
				   this.dialogProsCateTable = true
			  	});
			  },
			  handleProsEdit(index,row,status){
				  var params=this.$qs.stringify({
				  		is_show:1
				  })
				  this.$api.post('ShopAppoint/getProsCateList', params, res => {
				     this.prosCateTableData = res.data
				  });
				  if(status == 1){	//添加
						this.prosForm={
							id:'',
							pro_name:'',
							cate:'',
							is_show:1,
							sort:0
						}
				  }else{
					  var item = this.prosTableData[index];
					  this.prosForm = item
				  }
				  this.dialogAddPros = true
			  },
			  handleProsCateEdit(index, row, status){
				   if(status  ==  1){
					   this.prosCateForm = {
							  id:'',
							  cate_name:'',
							  is_show:1,
							  sort:0,
							  choose_pro_max:0,
							  time_peri:0
					   }
				   }else{
					  var item = this.prosCateTableData[index];
					  this.prosCateForm ={
						  id:item.id,
						  cate_name:item.cate_name,
						  is_show:item.is_show,
						  sort:item.sort,
						  choose_pro_max:item.choose_pro_max,
						  time_peri:item.time_peri
					  }
					  console.log(this.prosCateForm)
				   }
					this.dialogAddProsCate = true
			  },
			  saveProsCate(){		//保存科室
				   var params=this.$qs.stringify({
					   id:this.prosCateForm.id,
					   cate_name:this.prosCateForm.cate_name,
					   is_show:this.prosCateForm.is_show,
					   sort:this.prosCateForm.sort,
					   choose_pro_max:this.prosCateForm.choose_pro_max,
					   time_peri:this.prosCateForm.time_peri
				   })
				  this.$api.post('ShopAppoint/addProsCate',params,res=>{
					  this.dialogAddProsCate = false
					  this.handleProsCate()
					  this.$message.success(res.msg);
				  },err=>{
					   this.$message.error(err.msg);
				  })
			  },
			  savePros(){		//保存项目
				  var params = this.$qs.stringify({
					  id:this.prosForm.id,
					  pro_name:this.prosForm.pro_name,
					  is_show:this.prosForm.is_show,
					  sort:this.prosForm.sort,
					  cate:this.prosForm.cate
				  })
				  this.$api.post('ShopAppoint/addPros',params,res=>{
					  this.dialogAddPros = false
					  this.handlePros()
					  this.$message.success(res.msg);
				  },err=>{
					   this.$message.error(err.msg);
				  })
			  },
			  handleDeleteProsCate(index,row){	//删除科室提示
				  this.prosCateForm = row;
				  this.delVisible = true;
				  this.delMode = 'pros_cate'
			  },
			  handleDeletePros(index,row){	//删除项目
				  this.prosForm = row
				  this.delVisible = true
				  this.delMode = 'pros'
			  },
			  deleteRow(delmode){
				  var url = ''
				  var id = ''
				  if(delmode == 'Box'){
					 url = 'Box/delBox'
					 id = this.form.id
				  }
				  var params=this.$qs.stringify({
				      id: id
				  });
				  this.$api.post(url, params, res => {
					//this.handlePros();
				    this.getData();
					this.$message.success(res.msg+res.data+"条数据");
				  }, err => {
				      this.$message.error(err.msg);
				  });
				  this.delVisible = false;
			  },
			  handleConfig(){
					var params = this.$qs.stringify({
						sys_id:8,
					})
					this.dialogEmail = true
				    this.$api.post('Box/system_set', params,res=>{
						this.emailData =  res.data.list
					 
				  },err => {
				    this.emailData = []
				    this.$message.error(err.msg);
				 });
			  },
			  saveConfig(formName){

				  if(!this.emailData.imgid){
					this.$message.error('请上传图片');
					return false;
				  }
				  var params=this.$qs.stringify({
						sys_id:8,
						per_day:this.emailData.per_day,
						num:this.emailData.num,
						active_start_time:this.emailData.active_start_time,
						desc:this.emailData.desc,
						imgid:this.emailData.imgid,
						value:this.emailData.value,

				  });

				  this.$api.post('Box/saveSetting', params, res => {
						this.dialogEmail =  false
						this.$message.success(res.msg);
				  }, err => {
					  this.$message.error(err.msg);
				  });

				  
			  },
			  // 分页导航
			  handleCurrentChange(val) {
			      this.cur_page = val;
			      this.getData();
			  },
			  handleProsCurrentChange(val){
				  this.pros_cur_page = val
				   this.handlePros()
			  },
			  handleSelectionChange(val) {
			      this.multipleSelection = val;
			  }
			
		  }
	}
</script>

<style scoped>
	.handle-box {
	    margin-bottom: 20px;
	}
	
	.handle-select {
	    width: 120px;
	}
	
	.handle-input {
	    width: 300px;
	    display: inline-block;
	}
	.del-dialog-cnt{
	    font-size: 16px;
	    text-align: center
	}
	.table{
	    width: 100%;
	    font-size: 14px;
	}
	.red{
	    color: #ff0000;
	}
</style>
